import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';
@Injectable({ providedIn: 'root' })
export class ActiveUserGuard {
  constructor(public awsAuthService: AuthService) { }

  canActivate(): boolean {
    let authenticatedUser = this.awsAuthService._getAuthenticatedUser();
    if (authenticatedUser?.email_verified) {
      return true;
    } else {
      this.awsAuthService.logOut();
    }
    return false;
  }
}